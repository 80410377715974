
import { parse } from 'marked'
// import HeadingSM from '@/components/wrappers/HeadingSM.vue'
import VerticalLongSVG from '@/components/svg/detail/VerticalLongSVG.vue'
import Saw from '@/components/parts/Saw.vue'
import TriSVG from '@/components/svg/detail/TriSVG.vue'
// import Slider from '@/components/parts/Slider.vue'
import VideoSelect from '@/components/parts/video/VideoSelect.vue'
import Info from '@/components/parts/Info.vue'
export default {
  components: {
    // HeadingSM,
    VerticalLongSVG,
    Saw,
    TriSVG,
    VideoSelect,
    Info,
  },

  props: {
    section: {
      type: Object,
      default: () => ({}),
    },
    sectionIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      tl: null,
      playing: false,
    }
  },
  computed: {
    renderedBody() {
      return this.section.body ? parse(this.section.body) : ''
    },
  },
  mounted() {
    this.tl = this.$gsap.timeline({
      defaults: { ease: 'custom.1' },
      scrollTrigger: {
        trigger: this.$el,
        start: 'top bottom',
        // toggleActions: 'play reset play reverse',
      },
      onComplete: () => {
        heading.revert()
      },
    })
    this.tl.scrollTrigger.disable()

    this.tl.to(this.$refs.mask, { xPercent: this.section.swirl === 'top' ? -100 : 100, duration: 0.3 })

    const heading = new this.$SplitText(this.$refs.heading, { type: 'chars, words, lines', wordsClass: 'whitespace-pre' })
    this.tl.from(heading.chars, {
      opacity: 0,
      stagger: {
        each: 0.1,
        from: 'start',
        grid: 'auto',
        ease: 'power2.inOut',
      },
    })
    this.$refs.preheading && this.tl.from(this.$refs.preheading.$el, { opacity: 0, xPercent: -10, duration: 0.2 }, '<')

    this.tl.addLabel('go')

    // this.tl.to(this.$refs.slider.$refs.img[0].$el, { opacity: 1, duration: 0.5 }, '>')
    // this.autoplayer = this.$gsap.timeline()
    // this.autoplayer.set(this.$refs.slider.next, { delay: 3, onRepeat: this.$refs.slider.next, repeat: -1, repeatDelay: 3 })
    // this.tl.add(this.autoplayer)

    this.tl.from(this.$refs.tris.children, { opacity: 0, yPercent: -100, stagger: 0.1 }, 'go')
    this.$refs.body && this.tl.from(this.$refs.body, { opacity: 0, duration: 0.2 }, '>')

    this.$emit('sectionMounted')
  },
  beforeMount() {
    this.$nuxt.$on('go', this.anim)
  },
  beforeDestroy() {
    this.$nuxt.$off('go', this.anim)
  },
  methods: {
    infos(i) {
      let classes = ''
      // if (!this.$screen['2xl'] && i === 0) classes += 'place-self-end '
      // if (this.$screen['2xl']) {
      //   if (i <= 1) classes += 'place-self-end '
      // } else if (i === 0) classes += 'place-self-end '

      const firstCols = this.$screen['2xl'] ? [2, 5, 8, 11, 13] : [1, 3, 5, 7]
      if (firstCols.includes(i)) {
        classes += 'col-start-2 2xl:col-start-3 '
      }
      return classes
    },
    playVideo() {
      this.playing = true
    },
    anim() {
      this.tl.scrollTrigger.enable()
    },
    stopAutoplay() {
      this.tl.remove(this.autoplayer)
    },
  },
}
