
import AngleSVG from '@/components/svg/img/AngleSVG.vue'
import PlaySVG from '~/components/svg/PlaySVG.vue'
export default {
  components: {
    AngleSVG,
    Embed: () => import('@/components/parts/video/Embed.vue'),
    Local: () => import('@/components/parts/video/Local.vue'),
    PlaySVG,
  },
  props: {
    section: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      playing: false,
      tl: null,
    }
  },
  computed: {
    getComponent() {
      return this.section.videoLocal ? 'Local' : 'Embed'
    },
    currentProperties() {
      return this.section.videoLocal ? { video: this.section.videoLocal } : { video: this.section.videoEmbed }
    },
  },
  mounted() {
    // console.log('vref', this.$refs.video)
    // this.tl = this.$gsap.timeline({ paused: true })
    // this.tl.to(this.$refs.svg.$el.querySelectorAll('line'), { duration: 0.3, drawSVG: 0, stagger: 0.2 })
    // this.tl.to(this.$refs.play, { opacity: 0, duration: 0.4 }, '<')
    // this.tl.call(this.startVideo)
    // this.tl.from(this.$refs.video, { opacity: 0, duration: 0.4 }, '>')
    // this.tl.to(this.$refs.poster, { opacity: 0, duration: 0.4 }, '<')
    // this.tl.set(this.$refs.overlay, { pointerEvents: 'none' })
  },
  methods: {
    // startVideo() {
    //   this.playing = true
    // },
    play() {
      console.log('play', this.$refs.video.player)
      this.$refs.video.player.play()
      // this.tl.play().then(() => {
      //   // this.$nuxt.$emit('videoModal', this.section)
      //   this.playing = true
      // })
    },
  },
}
