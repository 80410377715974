
import TriXSSVG from '@/components/svg/detail/TriXSSVG.vue'

export default {
  components: { TriXSSVG },
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
}
